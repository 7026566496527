import { useNavigate } from "react-router-dom";
import "../../../../assets/css/PlantTree.css";
import TREE from "../../../../assets/image/Game/PlantTree/big-tree.png";
import BUTTONJOIN from "../../../../assets/image/Game/PlantTree/button-join.png";
import TITLE_PLANT from "../../../../assets/image/Game/PlantTree/title-plant.png";
import BG_GAME from "../../../../assets/image/Game/PlantTree/bg-game.png";
export default function BackgroundGame({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <div className="bg-joingam relative top-0">
      <div className="relative">
        <img src={BG_GAME} />
        <div className="absolute top-[2%] w-full flex justify-center">
          <img src={TITLE_PLANT} className="w-10/12" />
        </div>
      </div>
      {children}
    </div>
  );
}
