import MACHINE_TOP from "../../../assets/image/Game/LuckyMachine/machine-top.png";
import MACHINE_BOTTOM from "../../../assets/image/Game/LuckyMachine/machine-bottom.png";
import LOGO_FAMILY from "../../../assets/image/Game/LuckyMachine/logo-familymart.png";
import TWISTER from "../../../assets/image/Game/LuckyMachine/twister.png";
import GIFT from "../../../assets/image/Game/LuckyMachine/gift.png";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GameServices } from "../../../services/apiService/GameService";
import { LUOT_QUAY } from "../../../services/localService/localService";
import { GameDetailsModel } from "../../../models/GameModel";

export default function LuckyMachine() {
  const [count, setCount] = useState(-1);
  const [start, setStart] = useState(false);
  const [isLasted, setIsLasted] = useState(false);
  const [countRound, setCountRound] = useState(0);
  const [indexGift, setIndexGift] = useState(7);
  const [game, setBackgroundGame] = useState<GameDetailsModel>();
  const [isSpinning, setIsSpinning] = useState<boolean>(false);
  const [isAnnounce, setIsAnnounce] = useState<boolean>(false);
  const [transformBox, settransformBox] = useState<string>("");
  const [dataCallUrbox, setDataCallUrbox] = useState<number>(0);
  const [gift, setGift] = useState<any>();
  const [win, setwin] = useState(0);
  const [isError, setIsError] = useState<boolean>(false);
  const [messError, setMessError] = useState<string>("Error");
  const [segments, setsegments] = useState<any>([]);
  const [render, setRender] = useState<boolean>(false);
  const [isOpenPopup, setIsPopup] = useState<boolean>(false);
  const [lineId, setLineId] = useState();
  const [popUpGift, setPopupGift] = useState("no");
  let navigation = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { campaign_id, sale_id } = useParams<string>();

  useEffect(() => {
    getCalculator(sale_id, campaign_id);
    const test = [1, 0, 5, 6, 3, 2, 0];
    const a = test.findIndex((x) => x === 0);
    console.log(a);
  }, []);
  const getCalculator = (
    sale_id: string | undefined,
    campaign_id: string | undefined
  ) => {
    const params = {
      sale_id: sale_id,
      campaign_id: campaign_id,
    };
    GameServices.CalculatorWheelApi(params)
      .then((res: any) => {
        console.log(res);
        if (res.remaining_draw === 0) {
          setIsError(true);
          setMessError("Bạn đã hết lượt chơi !!!");
        } else {
          setPopupGift("no");
          setDataCallUrbox(res.remaining_draw);
          setsegments(res.lucky_draw_list.list_lucky_draw[0].list_draw);
          setBackgroundGame(res.lucky_draw_list.game_data);
          let itemWin = res.lucky_draw_list.list_lucky_draw[0].list_win[0];
          setLineId(itemWin?.line_id);
          console.log(itemWin);
          let codeWin = itemWin.gift_code;
          console.log(itemWin);
          let segmentsTemp = res.lucky_draw_list.list_lucky_draw[0].list_draw;
          console.log(segmentsTemp);
          localStorage.setItem(
            "GAME_DATA_LOCAL",
            JSON.stringify(res.lucky_draw_list.game_data)
          );
          localStorage.setItem("GIFT_WHEEL", JSON.stringify(itemWin));
          localStorage.setItem(LUOT_QUAY, res.remaining_draw);
          setGift(itemWin);
          const a = segmentsTemp.findIndex((x: any) => x.gift_code === codeWin);
          console.log(a);
          setIndexGift(a);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsError(true);
        setMessError(err);
        console.log(err);
      });
  };
  useEffect(() => {
    if (start) {
      const timer = setTimeout(() => {
        if (count < 9 && !isLasted) {
          if (count === 8) {
            setCountRound((prevCount) => prevCount + 1);
            setCount(0);
            if (countRound === 4) {
              setIsLasted(true);
            }
          } else {
            setCount((prevCount) => prevCount + 1);
          }
        } else if (isLasted) {
          if (count < indexGift) {
            setCount((prevCount) => prevCount + 1);
          }
          if (count === 8) {
            return () => clearTimeout(timer);
          }
          // setTimeout(() => {
          //   navigation(`/receive-code/${id}`);
          // }, 2000);
        }
      }, 125);
    }
  }, [count, isLasted, countRound, start]);
  function isEqual(obj1: any, obj2: any) {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }
  const handleRun = () => {
    const positions = [];
    setStart(true);
    if (!start) {
      const data = JSON.parse(localStorage.getItem("WHEEL_PHANTHUONG") || "{}");
      console.log(data);
      for (let i = 0; i < segments.length; i++) {
        if (isEqual(segments[i]?.gift_code, data?.gift_code)) {
          positions.push(i);
        }
      }
      console.log(positions);
    }
  };
  const rederArrayItem = () => {
    let items = [];
    for (let i = 0; i < 9; i++) {
      // console.log(i);
      items.push(
        <div
          className="h-hull rounded-md relative w-full flex justify-center"
          style={{ backgroundColor: count === i ? "#4D8AF8" : "#F0FBFE" }}
        >
          <img
            src={segments[i]?.gift_square_image_url}
            className="absolute top-1/2 -translate-y-1/2"
          />
        </div>
      );
    }
    return items;
  };
  return (
    <div className="relative">
      <div>{count}</div>
      <div className="relative bg-machine">
        <div className="relative ">
          <img src={MACHINE_TOP} />
          <div className="absolute flex justify-between top-3 w-full px-5">
            <div>
              <img src={LOGO_FAMILY} />
            </div>
            <div>
              <img src={TWISTER} />
            </div>
          </div>
          <div className="absolute top-0 py-16 h-full px-8 w-full">
            <div className="grid grid-cols-3 h-full gap-1 text-center">
              {rederArrayItem()}
            </div>
          </div>
        </div>
        <div className="relative -top-[1px]">
          <img src={MACHINE_BOTTOM} />
          <div
            className="absolute top-10 w-full text-center"
            onClick={handleRun}
          >
            hello
          </div>
        </div>
      </div>
    </div>
  );
}
