import { useNavigate } from "react-router-dom";
import "../../../assets/css/PlantTree.css";
import TREE from "../../../assets/image/Game/PlantTree/big-tree.png";
import BUTTONJOIN from "../../../assets/image/Game/PlantTree/button-join.png";
import TITLE_PLANT from "../../../assets/image/Game/PlantTree/title-plant.png";
import BackgroundGame from "./BackgroundGame/BackgroundGame";

export default function JoinGame() {
  const navigation = useNavigate();

  const handleNextLoading = () => {
    navigation("/play-game");
  };
  return (
    <BackgroundGame>
      <div className="absolute w-full text-center top-[63%] -translate-y-1/2">
        {/* <div className="absolute bottom-5 w-full z-10 ">
          <img src={TREE} className="relative left-1/2 -translate-x-1/2" />
        </div> */}
        {/* <div className="relative top-0 w-full left-1/2 -translate-x-1/2 land-tree z-0"></div> */}

        <button
          className="absolute w-full flex justify-center"
          onClick={handleNextLoading}
        >
          <img src={BUTTONJOIN} className="relative w-7/12" />
          <div className="absolute top-[45%] -translate-y-1/2 text-[20px] text-white">
            THAM GIA NGAY
          </div>
        </button>
      </div>
    </BackgroundGame>
  );
}
